import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import Section, { Label } from '../../components/Section';
import { SEO } from '../../components/SEO';
import {
  SignupButton,
  SignupButtonWithChoice
} from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  ContentAnalyticsDrops,
  ContentAnalyticsGrowth,
  ContentAnalyticsExport,
  ContentAnalyticsImpressions,
  FeatureGrid,
  ContentAnalyticsCompare,
  ContentAnalyticsRevisions
} from '../../components/FeatureGrid';
import { Link } from 'gatsby';
import { DemoButton } from '../../components/DemoButton';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/content-analytics/"
          title="Content Analytics for Affiliate Publishers"
          description="Optimize your commerce content with CRO tools tailored for affiliates."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '730px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Identify your best-performing affiliate content so you can create
            more like it
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Discover exactly which content types, affiliate products, and
            on-page elements are driving your revenue. Then, scale winning
            strategies across your entire site or portfolio.
          </Typography>
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Get started today" />
          </div>
        </PageHead>
        <div style={{ maxWidth: '700px', margin: '96px auto 48px' }}>
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 900
            }}
          >
            Content analytics and optimization tools for affiliate publishers
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Everything you need to understand what makes your audience click and
            buy, so you can dial in your commerce content strategy.
          </Typography>
        </div>
        <Section
          image="/images/features/new-heatmaps.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Conversion Heatmaps</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Heatmaps that show you which buttons, links, or creatives
                actually convert
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                <Link to="/product/heatmaps/">Affilimate's heatmaps</Link> show
                you not only which links earn, but what copy and visual
                representations get your readers to click and convert.
              </Typography>
              <Typography paragraph variant="body1">
                Identify and replace links that don't convert and distract from
                your real money-makers.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/new-page-level-reporting.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Revenue attribution</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Attribute revenue to pages on your website, automatically
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Attribute commissions to specific pages on your website to
                derive the true value of each piece of content through
                Affilimate's{' '}
                <Link to="/product/revenue-attribution/">
                  revenue attribution
                </Link>
                .
              </Typography>
              <Typography paragraph variant="body1">
                Track key metrics like clicks, revenue, RPM, and EPC so you can
                understand which categories and topics perform best.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/new-revisions.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Revisions</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Make content edits with confidence (and remember what you
                changed)
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Traditional A/B testing is tedious to set up and gets forgotten
                in the content production process.
              </Typography>
              <Typography paragraph variant="body1">
                Affilimate automatically splits your affiliate revenue by click
                date and <Link to="/product/revisions/">content revision</Link>,
                so you can identify which changes improved conversion.
              </Typography>
            </>
          }
        />
        <div style={{ textAlign: 'center', margin: '48px auto 96px' }}>
          <SignupButtonWithChoice text="Get started today" />
        </div>
        <div style={{ maxWidth: '900px', margin: '96px auto 48px' }}>
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 900
            }}
          >
            But wait, there's more
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Here are a few more ways you can put the insights from your content
            to use
          </Typography>
        </div>
        <FeatureGrid>
          <ContentAnalyticsImpressions />
          <ContentAnalyticsGrowth />
          <ContentAnalyticsExport />
          <ContentAnalyticsDrops />
          <ContentAnalyticsRevisions />
          <ContentAnalyticsCompare />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
